
  import Vue from 'vue';
  import * as api from '@/api';

  export default Vue.extend({
    props: {
      value: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        filterData: {
          search: '',
          roleId: null,
        },
        roleList: [] as api.Role[],
      };
    },
    async created() {
      const res = await api.getRoleList();
      if (!api.error(res)) {
        this.roleList = res.data;
      }
    },
    methods: {
      onSubmit() {
        this.$emit('input', { ...this.filterData });
      },
      onReset() {
        this.filterData = {
          search: '',
          roleId: null,
        };
        this.$emit('input', { ...this.filterData });
      },
    },
  });
