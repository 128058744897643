
import Vue from "vue";
import UserFilter from "@/components/UserFilter.vue";
import * as api from "@/api";
import { filter } from "vue/types/umd";
import moment from "moment";
import { disableUser } from "@/api";

export default Vue.extend({
  name: "UserList",
  components: { UserFilter },
  data() {
    return {
      limitTime: "",
      queryParams: {
        search: "",
        roleId: null,
        skipCount: 0,
        maxResultCount: 10,
        sorting: "createdAt DESC"
      },
      filterData: {
        search: "",
        roleId: null
      },
      pagination: {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10
      },
      userList: [] as api.User[],
      selectedUsers: [] as api.User[],
      loading: false
    };
  },
  watch: {
    filterData() {
      this.queryParams.roleId = this.filterData.roleId;
      this.queryParams.search = this.filterData.search;
    },
    queryParams: {
      deep: true,
      async handler() {
        await this.fetchData();
      }
    },
    pagination: {
      deep: true,
      handler(pagination) {
        this.queryParams.skipCount = (pagination.currentPage - 1) * pagination.pageSize;
        this.queryParams.maxResultCount = pagination.pageSize;
      }
    }
  },
  async created() {
    this.$store.commit("breadcrumbList", [
      {
        name: "用户列表",
        path: "list"
      }
    ]);
    await this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      const res = await api.getUserList(this.queryParams);
      this.loading = false;
      if (!api.error(res)) {
        this.pagination.totalCount = res.data.totalCount;
        this.userList = res.data.items;
      }
    },
    handleSorting(command) {
      this.queryParams.sorting = command;
    },
    addUser() {
      this.$store.commit("editUser", null);
      this.$router.push({
        name: "UserAdd"
      });
    },
    isExpired(limitTime) {
      if (moment(limitTime).endOf("day") < moment().endOf("day")) return true;
      return false;
    },
    async toggleUserEnable(users: api.User[], value) {
      if (users.length === 0) {
        this.$message({
          type: "warning",
          message: "未选中任何用户"
        });
        return;
      }
      const userIds = users.filter(user => user.isEnabled !== value).map(user => user.userId);
      const res = value ? await api.enableUser(userIds) : await api.disableUser(userIds);
      if (!api.error(res)) {
        users.forEach(user => {
          user.isEnabled = value;
        });
        this.$message({
          type: "success",
          message: "操作成功"
        });
      } else {
        this.$message({
          type: "error",
          message: res.error.message
        });
      }
    },
    handleSelectionChange(selection: api.User[]) {
      this.selectedUsers = selection;
    },
    async removeUser(users: api.User[]) {
      if (users.length === 0) {
        this.$message({
          type: "warning",
          message: "未选中任何用户"
        });
        return;
      }
      const userIds = users.map(user => user.userId);
      this.$confirm("删除用户不可恢复", "你确定要删除吗？", {
        distinguishCancelAndClose: true,
        confirmButtonText: "确定删除",
        confirmButtonClass: "bg-danger",
        cancelButtonText: "取消",
        iconClass: "el-icon-question"
      }).then(async () => {
        const res = await api.removeUser({ userIds });
        if (!api.error(res)) {
          this.$message({
            type: "success",
            message: "操作成功"
          });
          this.fetchData();
        } else {
          this.$message({
            type: "error",
            message: res.error.message
          });
        }
      });
    },
    async editUser(user: api.User) {
      this.$store.commit("editUser", user);
      this.$router.push({
        name: "UserEdit"
      });
    },
    async setLimitTime(userId: number, e: any) {
      const res = await api.setLimitTime({
        userIds: [userId],
        limitTime: moment(e)
      });
      console.log();

      if (!api.error(res)) {
        this.$message.success("设置到期时间成功！");
        if (e < new Date()) {
          disableUser([`${userId}`]).then(() => {
            this.fetchData();
          });
          return;
        } else {
          this.fetchData();
        }
      } else {
        this.$message({
          type: "error",
          message: res.error.message
        });
      }
    }
  }
});
